// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-draft-page-group-js": () => import("./../../../src/pages/draftPageGroup.js" /* webpackChunkName: "component---src-pages-draft-page-group-js" */),
  "component---src-pages-draft-page-news-js": () => import("./../../../src/pages/draftPageNews.js" /* webpackChunkName: "component---src-pages-draft-page-news-js" */),
  "component---src-pages-draft-page-project-js": () => import("./../../../src/pages/draftPageProject.js" /* webpackChunkName: "component---src-pages-draft-page-project-js" */),
  "component---src-pages-group-detail-microcms-portal-group-portal-group-id-js": () => import("./../../../src/pages/group-detail/{microcmsPortalGroup.portalGroupId}.js" /* webpackChunkName: "component---src-pages-group-detail-microcms-portal-group-portal-group-id-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-detail-microcms-portal-news-portal-news-id-js": () => import("./../../../src/pages/news-detail/{microcmsPortalNews.portalNewsId}.js" /* webpackChunkName: "component---src-pages-news-detail-microcms-portal-news-portal-news-id-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-project-detail-microcms-portal-project-portal-project-id-js": () => import("./../../../src/pages/project-detail/{microcmsPortalProject.portalProjectId}.js" /* webpackChunkName: "component---src-pages-project-detail-microcms-portal-project-portal-project-id-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-template-group-page-js": () => import("./../../../src/template/group-page.js" /* webpackChunkName: "component---src-template-group-page-js" */),
  "component---src-template-news-page-js": () => import("./../../../src/template/news-page.js" /* webpackChunkName: "component---src-template-news-page-js" */),
  "component---src-template-project-page-js": () => import("./../../../src/template/project-page.js" /* webpackChunkName: "component---src-template-project-page-js" */)
}

